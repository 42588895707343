import './flexboxgrid.min.css';
import './App.css';
import header from './images/banner_2-01.png';
import banner from './images/heading_banner.jpg';
import janine from './images/Janine.jpg';
import latest_news from './images/Latestnews_banner.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={header} className="App-logo" alt="logo" />
      </header>
      <div>
        <img src={banner} alt="banner" className="full-image" />
      </div>
      <div className="container-wrapper">
        <div className="col-xs-12">
          <div className="city-wrapper"></div>
        </div>
      </div>
      <div className="container-wrapper">
        <div className='row middle-xs'>
          <div className='col-xs-12 col-md-6'>
            <h1>Advocating for creative communities and charities since 1975</h1>
            <p>Headquartered in Minneapolis, The Darryl Fanton Foundation is a philanthropist organization who aides domestic violence protection groups, organizations dedicated to the treatment of substance abuse, and charities who are champions for children.</p>
            <p>The DFF began in the mid 1970s as a strictly financial-based charity, fueled by the estate of its namesake.  Over recent years DFF has blossomed to incorporate food drives, fundraisers, and support groups serving the Twin Cities.</p>
            <p>Generously started by former Rock Star! Janine Fanton, the DFF honors her late brother Darryl who tragically lost his life in 1973. </p>
            <p className='txt-center'><a href="#" className='btn'>LEARN MORE</a></p>
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='image-wrapper'>
              <img src={janine} alt="Janine" className='block-img' />
            </div>
            <label>Janine Fanton, Founder and former Executive Director, now serves in an advisory role. </label>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-12">
            <img src={latest_news} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <h1>Our Partners</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-4 partners'>
            <hr></hr>
            <p>A.D.A.E.</p>
            <p>Alcohol Abusers Assembly</p>
            <p>Celia's Charms</p>
            <p>Children of the World Charity Fund</p>
          </div>
          <div className='col-xs-12 col-md-4 partners'>
            <hr></hr>
            <p>Chorus for a Cure</p>

            <p>Double Dutch for Heart</p>

            <p>F.A.I.D.</p>

            <p>Fostering Minnesota</p>
          </div>
          <div className='col-xs-12 col-md-4 partners'>
            <hr></hr>
            <p>Healing Haven</p>

            <p>Homeless Abuse Protection Coalition</p>

            <p>Humanitarian Aid Alliance</p>

            <p>Janie's Market</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-4 partners'>
            <hr></hr>
            <p>Jogging for Change</p>

            <p>Lads and Lasses Club</p>

            <p>Listening, Learning, Living (LLL)</p>

            <p>Midway House</p>
          </div>
          <div className='col-xs-12 col-md-4 partners'>
            <hr></hr>
            <p>Minnesota Mamas</p>

            <p>Narcotic Recovery Circle</p>

            <p>Noah's Song</p>

            <p>Safe Sanctuary</p>
          </div>
          <div className='col-xs-12 col-md-4 partners'>
            <hr></hr>
            <p>Simple Well-Being</p>

            <p>The Center for the Arts St. Paul</p>

            <p>T.K. Henry Treatment Center</p>

            <p>Twin Cities Relief Center (TCRC)</p>
          </div>
        </div>
      </div>
      <footer>
        <p>Copyright ©1999 Darryl Fanton Foundation - All Rights Reserved</p> 
      </footer>
    </div>
  );
}

export default App;
